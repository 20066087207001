.slide {
    background-color: black;
}

.cardss {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background-color: rgb(146, 27, 27);
    border-radius: 30px;
    box-shadow: 0 0 50px rgb(56, 56, 56);
    margin: 100px 0;
    margin-left: 10%;
    margin-right: 10%;
    padding: 1cm;




    .pako-imagee {
        width: 90%;
        padding-bottom: 1cm;

    }

    .pako-titlee {
        display: flex;
        padding-bottom: 1cm;
        justify-content: center;
    }

    .pako-titlee {
        display: flex;
        justify-content: center;
    }


    .pako-descriptionn {
        text-align: center;
        color: rgb(95, 95, 95);
    }


}

@media only screen and (max-width: 800px) {

    .cardss {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        background-color: rgb(146, 27, 27);
        border-radius: 30px;
        box-shadow: 0 0 50px rgb(56, 56, 56);
        margin: 40px ;
        margin-left: 20%;
        margin-right: 15%;
        padding: 10px;

        .pako-imagee {
            width: 80%;
            padding-bottom:20px;

        }

        .pako-titlee {
            display: flex;
            padding-bottom: 20px;
            justify-content: center;
        }

        .pako-titlee {
            display: flex;
            justify-content: center;
        }


        .pako-descriptionn {
            text-align: center;
            color: rgb(95, 95, 95);
        }


    }
}