.logo img {
    width: 180px;

}

.fontnavbar {

    color: white;
    margin-left: 5cm;
    font-size: large;


}

.buttonnn {
    display: flex;
    padding-left: 10px;
    justify-content: space-evenly;

    .buttonni{
        margin-right: 100px;
    }
    
    
}
.navlink{
    color: white;
    height: 100%;
    cursor: pointer;

    &:hover {
      color: rgb(20, 63, 131);
      transition: 200ms ease-in;
    }
}

.wifi {
    border-right: 2px solid white;
    padding-left: 3cm;
    padding-right: 10px;
    padding-top: 5px;
    color: white;
    justify-content: end;
    

}
@media screen and (max-width: 800px) {
    .logo img {
        width: 120px;
    }
    .fontnavbar {

        color: white;
        margin-left: 0cm;
        font-size: large;
    
    
    }
    .buttonnn {
        display: flex;
   
        justify-content: space-evenly;
        
    }
    
    .wifi {
        border-right: 2px solid white;
       
        padding-right: 10px;
        padding-top: 5px;
        color: white;
        display: flex;
        justify-content: end;
    
    }

}

