.divipar{
    margin: 2cm;
    background-color: rgb(220, 220, 220);
    border-radius: 5px;
    box-shadow: 0 0 20px rgb(119, 119, 119);
   
}
.title{
   margin-left: 10%;
   padding:10px;
   font-size: 30px

}
.button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}