.cards {
  display: flex;

  justify-content: space-around;

  background-color: rgb(225, 225, 225);
  border-radius: 5px;
  box-shadow: 0 0 20px rgb(153, 153, 153);
  margin: 20px;
  padding: 1px;
  height: 15.5cm;
  width: 10.5cm;





  .pako-image {
    width: 100%;
    padding-bottom: 10px;

  }

  .pako-title {
    display: flex;
    padding-bottom: 20px;
    justify-content: center;
  }

  .pako-date {
    display: flex;
    padding-bottom: 20px;
    justify-content: end;

  }

  .pako-description {
    text-align: center;
    color: rgb(95, 95, 95);
    font-size: 13px;
  }

  .buttonn {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    align-content: stretch;
    padding-top: 1cm;


  }
}