
.Firstttt {
  background-color: rgb(0, 0, 0);
  border-radius: 24px;
  box-shadow: 0 0 30px rgb(255, 255, 255);
  margin-top: 1.5cm;
  margin-bottom: 3cm;
  margin-left: 35%;
  margin-right: 35%;
padding-top: 2cm;
padding-bottom: 2cm;
padding-left:1cm ;
padding-right:1cm ;
}
.background  {
padding: 3cm;
}
.texttt{
  display: flex;
  justify-content: center;
  margin-bottom: 1cm;
}

@media screen and (max-width: 800px) {
  
.Firstttt {
  background-color: rgb(0, 0, 0);
  border-radius: 24px;
  box-shadow: 0 0 30px rgb(255, 255, 255);
  margin-top: 1.5cm;
  margin-bottom: 3cm;
  margin-left: 0%;
  margin-right: 0%;
padding-top: 1cm;
padding-bottom: 2cm;
padding-left:0.5cm ;
padding-right:0.5cm ;
}
.background  {
padding: 1cm;
}
.texttt{
  display: flex;
  justify-content: center;
  margin-bottom: 1cm;
}

}