.textarea {
    text-align: center;
    margin-top: 1.5cm;
    color: red;
}

.Firsttt {
    background-color: rgb(0, 0, 0);
 
    margin-top: 1.5cm;
    margin-bottom: 3cm;
    margin-left: 8cm;
    margin-right: 8cm;
    padding: 1cm;
    border-radius: 24px;
    box-shadow: 0 0 30px black;

    .aaaaa h3 {
        display: flex;
        justify-content: center;
        margin-top: 1cm;
        margin-bottom: 1cm;
        color: white;
        
        
    }
}

@media screen and (max-width: 800px) {
    .textarea {
        text-align: center;
        margin-top: 1.5cm;
        color: red;
    }
    .Firsttt {
        background-color: rgb(0, 0, 0);
     
        margin-top: 1cm;
        margin-bottom: 1cm;
        margin-left: 1cm;
        margin-right: 1cm;
        padding: 1cm;
        border-radius: 24px;
        box-shadow: 0 0 30px black;
    
    }
    .aaaaa h3 {
        display: flex;
        justify-content: center;
        margin-top: 1cm;
        margin-bottom: 1cm;
        color: white;
        
        
    }
  
  }