
.Firsttttt{
    background-color: rgb(8, 45, 96);
    border-radius: 13px;
    box-shadow: 0 0 50px rgb(20, 2, 2);
    margin-bottom: 3cm;
    margin-left: 50%;
    margin-right:-130%;
  padding-top: 2cm;
  padding-bottom: 2cm;
  padding-left:1cm ;
  padding-right:1cm ;
  }

  .texttttt{
    display: flex;
    justify-content: center;
    margin-bottom: 1cm;
   color: white;
  }
  
  @media screen and (max-width: 800px) {
    .Firsttttt {
    background-color: rgb(8, 45, 96);
    border-radius: 24px;
    box-shadow: 0 0 30px rgb(255, 255, 255);
    margin-top: 1.5cm;
    margin-bottom: 3cm;
    margin-left: 1.4%;
    margin-right: 1.4%;
  padding-top: 1cm;
  padding-bottom: 2cm;
  padding-left:0.5cm ;
  padding-right:0.5cm ;
  }
  .texttt{
    display: flex;
    justify-content: center;
    margin-bottom: 1cm;
  }
  
  }