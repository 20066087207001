.bledi {
    background-color: black;
    border-top: 1px dotted rgb(255, 255, 255);

}

.text {
    color: white;

}

@media screen and (max-width: 800px) {
    .bb {
        width: 180%;


    }
    .bb h6 {
       font-size: small;


    }

    .bbb {
        width: 100%;
        margin-left: 2cm;


    }
    .bbb h6 {
        font-size: small;
 
 
     }

    .parageafii {
        padding-left: 2cm;
        
    }
    .logofooter img{
        width: 65%;
        margin-bottom: 80px;
        margin-left: 36%;

       
    }


}