.kushtet_font{
    padding-top: 3cm;
    padding-bottom:2cm ;
margin-left: 10px;
color: red;
}
.kushtet{
    padding-bottom: 2cm;
    
}
.kushtettt{
    display: flex;

justify-content: center;
padding-bottom: 2cm;
}