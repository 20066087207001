.fotooo img {
    width: 100%;
}

.textt {
    border-top: 1px solid rgb(255, 255, 255);
    display: flex;
    padding-top: 2cm;
    padding-bottom: 2cm;
    justify-content: center;



}

.firstbody {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
    margin-bottom: 2cm;
}


.tabss{
    margin-left: 30px;
    margin-right: 40px;
    margin-top: 5cm;
}

@media screen and (max-width: 800px) {
    .tabss {
        display: flex;
        width: 80%;
        font-size: 13px;
        padding-bottom: 0.5cm;
        margin-left: 40px;
        margin-top: 1cm;

    }

    .fotoooo img {
        width: 60%;
margin-left: 1.6cm;
    }

    .firstbody {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .textt {
        border-top: 1px solid rgb(255, 255, 255);
        display: flex;
        padding-top: 70px;
        padding-bottom: 0px;
        justify-content: center;
    
    
    
    }
}