.font{
    display: flex;
    color: white;
    background-color: black;
    padding-top: 2cm;
    padding-bottom: 10px;
    justify-content: center;


}
.font1{
    display: flex;
    color: white;
    background-color: black;
    justify-content: center;
}
.fontpakot{
    display: flex;
    color: rgb(255, 255, 255);
    background-color: black;
    justify-content: center;
    padding-bottom: 40px;
}

.fundi{
background-color: black;
padding-bottom: 1cm;
display:flex;


}
.foto5 img{
    width: 50%;
    margin-left: 3cm;

}
.Accordion{
    margin-top: 2cm;
}
.pytjetee{
    display: flex;
    color: white;
    background-color: black;
    padding-top: 2cm;

    padding-left:18cm;
}


@media screen and (max-width: 800px) {
    .font{
        display: flex;
        color: white;
        background-color: black;
        padding-top: 2cm;
        padding-bottom: 10px;
        justify-content: center;
    
    
        
    }
    .fontpakot{
        display: flex;
        color: rgb(255, 255, 255);
        background-color: black;
        justify-content: center;
        padding-bottom: 40px;
    }
    .fundi{
    background-color: black;
    padding-bottom: 1cm;
    display:flex;
    flex-direction: column; 

    
    
    }
    .foto5 img{
        width: 50%;
        margin-left: 3cm;
    
    }
    .Accordion{
        margin-top: 2cm;
    }
    .pytjetee h2{
        display: flex;
        color: white;
        background-color: black;
     
    
       
    }
  
  }