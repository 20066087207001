.sherbimet {
    background-color: rgb(255, 255, 255);

    .sherbimet_foto img {
        width: 100%;

    }

    .sherbimet_font {
        display: flex;
        justify-content: center;
        color: rgb(0, 0, 0);
        padding: 2cm;

    }

    .sherbimet_foto1 img {
        margin-left: 1cm;
    }

    .sherbimett {
        display: flex;

    }

    .kushtet {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 4cm;
        padding-bottom: 1.5cm;
    }

    .sherbimet_fontt {
        margin-top: 6cm;
        margin-left: 2cm;
        margin-right: 1cm;
    }

    .sherbimet_pako {
        display: flex;
        flex-direction: row;

        justify-content: space-evenly;
        align-items: center;
        align-content: center;

        padding-left: 1.5cm;
        padding-bottom: 3cm;
    }

    .sherbime_pakot {
        display: flex;
        flex-direction: column;



        img {
            width: 85%;
            border-radius: 30px 30px 0 0;

        }

        .sherbime_button {
            width: 85%;
            border-radius: 2px;
        }
    }
}

@media screen and (max-width: 800px) {
    .sherbimet {
        background-color: rgb(255, 255, 255);

        .sherbimet_foto img {
            width: 100%;

        }

        .sherbimet_font {
            display: flex;
            justify-content: center;
            color: rgb(0, 0, 0);
            padding: 1cm;


        }

        .sherbimet_foto1 img {
        
            margin-left: 1cm;
            width: 120%;
          
        }

        .sherbimett {
            display: flex;


        }

        .kushtet {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1cm;
            padding-bottom: 1.5cm;
        }

        .sherbimet_fontt h3 {
            display: flex;
            font-size: 14px;
    
         

        }

        .sherbimet_pako {
            display: flex;
            flex-direction: row;

            justify-content: space-evenly;
            align-items: center;
            align-content: center;

            padding-left: 1.5cm;
            padding-bottom: 3cm;
        }

        .sherbime_pakot {
            display: flex;
            flex-direction: column;



            img {
                width: 85%;
                border-radius: 30px 30px 0 0;

            }

            .sherbime_button {
                width: 85%;
                border-radius: 2px;
            }
        }
    }


}