.fontt{
    border-top: 1px solid rgb(255, 255, 255);
    display: flex;
    color: white;
    background-color: black;
    padding-top: 1cm;
    padding-bottom: 1cm;
    justify-content: center;
}

.video video{
    width: 100%;
}