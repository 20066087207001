.homee {

    .areaa {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;

        }
    }
   
    .textti h3 {
        display: flex;
        justify-content: center;
        margin-top: 2cm;
        margin-bottom: 2cm
        
    }

    .img img{
       margin-left: 2cm;
     }

}
@media screen and (max-width: 800px) {
    .areaa {
        display: flex;
        flex-direction: row;
      
    }

    .textti h3 {
        display: flex;
        justify-content: center;
font-size: 14px;
    
        
    }
    .img img{
       width:80% ;
    }
}